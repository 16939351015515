import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Container,
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  getSpecificTransaction,
  setTransactionDecision,
} from "../../../../services/transactions";
import { useDispatch } from "react-redux";
import { fetchAccessToken } from "../../../../redux";
import TableSkeleton from "../../../../components/skeletons/TableSkeleton";

const useTransactionUpdate = (token) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const updateTransaction = useCallback(
    async (transactionUuid, decision, actor = "user") => {
      if (!token) {
        setError("Token is missing. Please log in again.");
        return null;
      }

      setIsLoading(true);
      setError(null);
      setSuccess(false);

      try {
        const data = await setTransactionDecision(
          token,
          transactionUuid,
          decision
        );
        setSuccess(true);
        return data;
      } catch (err) {
        setError(
          err.message || "An error occurred while updating the transaction."
        );
        console.error("Transaction update error:", err);
      } finally {
        setIsLoading(false);
      }
    },
    [token]
  );

  return { updateTransaction, isLoading, error, success };
};

const DocumentDetailsSection = ({ title, documentData }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={2}>
        {documentData &&
          Object.entries(documentData).map(([key, value]) => (
            <Grid item xs={6} key={key}>
              <Typography variant="body2" color="text.secondary">
                {key
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}
              </Typography>
              <Typography variant="body1">{value || "N/A"}</Typography>
            </Grid>
          ))}
      </Grid>
    </AccordionDetails>
  </Accordion>
);

const BiometricsSection = ({ biometrics }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>Biometrics</Typography>
    </AccordionSummary>
    <AccordionDetails>
      {biometrics &&
        biometrics.map((bio, index) => {
          const { image, ...otherFields } = bio;
          return (
            <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
              {Object.entries(otherFields).map(([key, value]) => (
                <Grid item xs={6} key={key}>
                  <Typography variant="body2" color="text.secondary">
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
                  </Typography>
                  <Typography variant="body1">{value || "N/A"}</Typography>
                </Grid>
              ))}
              {image && (
                <Grid item xs={12}>
                  <img
                    src={`data:image/jpeg;base64,${image}`}
                    alt="Biometric"
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "200px",
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                  />
                </Grid>
              )}
            </Grid>
          );
        })}
    </AccordionDetails>
  </Accordion>
);

const ValidationStatusSection = ({ validationStatus }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>Validation Status</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={2}>
        {validationStatus &&
          Object.entries(validationStatus).map(([key, value]) => (
            <Grid item xs={6} key={key}>
              <Typography variant="body2" color="text.secondary">
                {key
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}
              </Typography>
              <Typography variant="body1">
                {value ? "Passed" : "Failed"}
              </Typography>
            </Grid>
          ))}
      </Grid>
    </AccordionDetails>
  </Accordion>
);

const TransactionCard = ({ transaction, token, onUpdate }) => {
  const { updateTransaction, isLoading, error, success } =
    useTransactionUpdate(token);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  if (!transaction) {
    return (
      <Typography variant="body1" color="text.secondary" align="center">
        Transaction data is unavailable.
      </Typography>
    );
  }

  const handleUpdate = async (status) => {
    const result = await updateTransaction(
      transaction.transaction.transactionUuid,
      status
    );

    if (result) {
      setOpenSnackbar(true);
      onUpdate && onUpdate(transaction.transactionUuid, status);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Transaction Status:{" "}
          <strong>{transaction.transaction.transactionStatus}</strong>
        </Typography>

        <Grid container spacing={3} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6}>
            <Typography color="text.secondary" variant="caption">
              Traveler ID
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {transaction.transaction.travelerId}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography color="text.secondary" variant="caption">
              Travel ID
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {transaction.transaction.travelId}
            </Typography>
          </Grid>
        </Grid>

        <DocumentDetailsSection
          title="DTV-VC"
          documentData={transaction.dtoVC}
        />
        <DocumentDetailsSection
          title="DTC-PC"
          documentData={transaction.dtoPC}
        />
        <DocumentDetailsSection
          title="EMRTD"
          documentData={transaction.emrtd}
        />
        <BiometricsSection biometrics={transaction.biometrics} />
        <ValidationStatusSection
          validationStatus={transaction.validationStatus}
        />

        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            align="center"
            sx={{ mb: 2 }}
          >
            Update Transaction Status
          </Typography>
          <Grid container spacing={2}>
            {[
              {
                status: "DTC_PC_VERIFICATION_REQUIRED",
                color: "primary",
              },
              {
                status: "EMRTD_VERIFICATION_REQUIRED",
                color: "primary",
              },
              {
                status: "DETAILED_VERIFICATION_NEEDED",
                color: "primary",
              },
              {
                status: "CLOSED",
                color: "error",
              },
            ].map(({ status, color }) => (
              <Grid item xs={12} sm={6} md={3} key={status}>
                <Button
                  variant="contained"
                  color={color}
                  onClick={() => handleUpdate(status)}
                  disabled={isLoading}
                  fullWidth
                  sx={{ height: 66 }}
                >
                  {status.replace(/_/g, " ").toUpperCase()}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* <Grid item xs={12} sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            {[
              "DTC_PC_VERIFICATION_REQUIRED",
              "EMRTD_VERIFICATION_REQUIRED",
              "DETAILED_VERIFICATION_NEEDED",
              "CLOSED",
            ].map((status) => (
              <Grid item xs={12} sm={6} md={3} key={status}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleUpdate(status);
                  }}
                  disabled={isLoading}
                  fullWidth
                >
                  {status.replace(/_/g, " ").toUpperCase()}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid> */}
      </CardContent>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={isLoading ? "info" : success ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isLoading
            ? "Updating transaction..."
            : success
            ? "Transaction updated successfully!"
            : error || "Failed to update transaction"}
        </Alert>
      </Snackbar>
    </Card>
  );
};

const TransactionWithDetails = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const [transactionData, setTransactionData] = useState(null);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const storedUsername =
    localStorage.getItem("username") || sessionStorage.getItem("username");
  const storedPassword =
    localStorage.getItem("password") || sessionStorage.getItem("password");

  useEffect(() => {
    const fetchTokenAndTransaction = async () => {
      setIsLoading(true);
      try {
        if (storedUsername && storedPassword) {
          const fetchedToken = await dispatch(
            fetchAccessToken({
              username: storedUsername,
              password: storedPassword,
            })
          ).unwrap();
          if (fetchedToken) {
            setToken(fetchedToken);
            const transaction = await getSpecificTransaction(
              fetchedToken,
              uuid,
              true
            );
            setTransactionData(transaction);
          }
        }
      } catch (err) {
        setError(err.message || "Failed to fetch data");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTokenAndTransaction();
  }, [uuid, storedUsername, storedPassword, dispatch]);

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {isLoading ? (
        <Box sx={{ mt: 2, pl: 10, pr: 10 }}>
          <TableSkeleton />
        </Box>
      ) : error ? (
        <Typography variant="body1" color="error" align="center">
          {error}
        </Typography>
      ) : (
        transactionData && (
          <TransactionCard
            transaction={transactionData}
            token={token}
            onUpdate={(uuid, newStatus) =>
              setTransactionData((prev) =>
                prev.transactionUuid === uuid
                  ? { ...prev, transactionStatus: newStatus }
                  : prev
              )
            }
          />
        )
      )}
    </Container>
  );
};

export default TransactionWithDetails;
