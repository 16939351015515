import React, { useState } from "react";
import NavItem from "./NavItem";
import TextConstants from "../../../utils/constants/TextConstants";
import HomeIcon from "@mui/icons-material/Home";
// import InfoIcon from "@mui/icons-material/Info";
import BarChartIcon from "@mui/icons-material/BarChart";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { AccountCircle } from "@mui/icons-material";
import { Menu, MenuItem, IconButton } from "@mui/material";

export const NavItems = ({ onLogout, navigate }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const handleProfileNavigate = () => {
    handleProfileClose();
    navigate("/profile");
  };

  const handleLogout = () => {
    onLogout();
    navigate("/");
  };

  const navItems = [
    { icon: HomeIcon, label: TextConstants.DASHBOARD_BUTTON, path: "/" },
    {
      icon: BarChartIcon,
      label: TextConstants.STATISTICS_BUTTON,
      path: "/statistics",
    },
    // { icon: InfoIcon, label: TextConstants.ABOUT_BUTTON, path: "/about" },
  ];

  return (
    <div>
      {/* Regular navigation items */}
      {navItems.map((item) => (
        <NavItem
          key={item.label}
          icon={item.icon}
          label={item.label}
          onClick={() => navigate(item.path)}
        />
      ))}

      {/* Profile dropdown menu */}
      <IconButton
        onClick={handleProfileClick}
        aria-controls="profile-menu"
        aria-haspopup="true"
        sx={{
          color: "white", // Set icon color to white
          // border: "2px solid white", // Add white border for outline effect
          borderRadius: "30%", // Ensure the icon has a circular border
          padding: "5px", // Adjust padding if necessary
        }}
      >
        <AccountCircle />
      </IconButton>

      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProfileClose}
      >
        <MenuItem onClick={handleProfileNavigate}>
          {TextConstants.PROFILE_BUTTON}
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ExitToAppIcon style={{ marginRight: 8 }} />
          {TextConstants.LOGOUT_BUTTON}
        </MenuItem>
      </Menu>
    </div>
  );
};
