import React from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CloseIcon from "@mui/icons-material/Close";
import { keyframes } from "@mui/system";
import { useDispatch } from "react-redux";
import { removeBorderAlert } from "../../redux/slices/borderAlertsSlice";

// Define slide-in animation
const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Define pulse animation for urgent alerts
const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 152, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 152, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 152, 0, 0);
  }
`;

const BorderAlerts = ({ alerts }) => {
  const dispatch = useDispatch();

  const handleRemoveAlert = (index) => {
    dispatch(removeBorderAlert(index));
  };

  const getSeverity = (count) => {
    // if (count >= 5) return "error";
    // if (count >= 3) return "warning";
    // return "info";
    return "error";
  };

  const getAlertStyles = (count) => {
    const baseStyles = {
      borderRadius: "8px",
      backdropFilter: "blur(10px)",
      border: "1px solid",
      animation: `${slideIn} 0.5s ease-out`,
      transition: "all 0.3s ease",
      "&:hover": {
        transform: "translateY(-2px)",
        boxShadow: 4,
      },
    };

    return {
      ...baseStyles,
      backgroundColor: "rgba(253, 237, 237, 0.95)",
      borderColor: "error.main",
      animation: `${pulse} 2s infinite`,
    };

    // if (count >= 5) {
    //   return {
    //     ...baseStyles,
    //     backgroundColor: "rgba(253, 237, 237, 0.95)",
    //     borderColor: "error.main",
    //     animation: `${pulse} 2s infinite`,
    //   };
    // }
    // if (count >= 3) {
    //   return {
    //     ...baseStyles,
    //     backgroundColor: "rgba(255, 244, 229, 0.95)",
    //     borderColor: "warning.main",
    //   };
    // }
    // return {
    //   ...baseStyles,
    //   backgroundColor: "rgba(229, 246, 253, 0.95)",
    //   borderColor: "info.main",
    // };
  };

  if (alerts.length === 0) {
    return null;
  }

  return (
    alerts.length >= 1 && (
      <Box
        sx={{
          position: "fixed",
          top: 65,
          right: 24,
          zIndex: 1300,
          maxWidth: "400px",
          minWidth: "320px",
        }}
      >
        <Stack spacing={2}>
          {alerts.map((alert, index) => (
            <Collapse key={index} in={true}>
              <Alert
                severity={getSeverity(alert.count)}
                icon={
                  <WarningAmberIcon
                    sx={{
                      animation:
                        alert.count >= 5 ? `${pulse} 2s infinite` : "none",
                    }}
                  />
                }
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => handleRemoveAlert(index)}
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                      },
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={getAlertStyles(alert.count)}
              >
                <AlertTitle
                  sx={{
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {alert.device}
                </AlertTitle>
                <Typography
                  variant="body2"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    color: "text.secondary",
                  }}
                >
                  <strong>{alert.alert?.detected}</strong> {alert.description}
                </Typography>
              </Alert>
            </Collapse>
          ))}
        </Stack>
      </Box>
    )
  );
};

export default BorderAlerts;
