export const preprocessVehicleData = (vehicle) => {
  return {
    id: vehicle._id || Math.random().toString(),
    licensePlate: vehicle.licensePlate || "Unknown",
    registrationNumber: vehicle.registrationNumber || "",
    lprConfidence: vehicle.licensePlateRecognition?.confidenceScore || 0.0,
    licensePlateImage: vehicle.licensePlateRecognition?.licensePlateImage || "",
    carColor: vehicle.licensePlateRecognition?.carColor || "",
    colorConfidence: vehicle.licensePlateRecognition?.colorConfidence || 0.0,
    carType: vehicle.licensePlateRecognition?.carType || "",
    typeConfidence: vehicle.licensePlateRecognition?.typeConfidence || 0.0,
    typeReasoning: vehicle.licensePlateRecognition?.typeReasoning || "",
    xaiLicensePlateImage:
      vehicle.licensePlateRecognition?.xaiLicensePlateImage || "",
    facialMatchScore: vehicle.faceMatching?.matchScore || 0.0,
    thermalConfidence: vehicle.thermalImageProcessing?.confidenceScore || 0.0,
    passengerCount: vehicle.thermalImageProcessing?.passengerCount || 0,
    passengers: vehicle.thermalImageProcessing?.passengers || [],
    thermalImage: vehicle.thermalImageProcessing?.thermalImage || "",
    riskScore: vehicle.riskScore || 0.0,
    xrayImage: vehicle.xrayScan?.image || "",
    verified: vehicle?.verified || false,
    verificationMessage: vehicle?.verificationMessage || "",
    datetime: vehicle?.datetime || "",
  };
};

// TODO: Fix the response schema
export const preprocessTravelerData = (traveler) => {
  return {
    id: traveler._id || Math.random().toString(),
    fullName: traveler.document.givenName || "",
    number: traveler.document?.number || "",
    nationality: traveler.document?.nationality || "",
    expirationOn: traveler.document?.expirationOn || "",
    issuerState: traveler.document?.issuerState || "",
    documentAuthenticated:
      traveler.validationStatus?.documentAuthenticated || false,
    livenessCheckPassed:
      traveler.validationStatus?.livenessCheckPassed || false,
    biometryMatched: traveler.validationStatus?.biometryMatched || false,
    verified: traveler?.dssValidationStatus.verified || false,
    url: traveler?.dssValidationStatus.url || "",
  };
};

export const calculateRiskScore = (
  facialMatching,
  licensePlateConfidence,
  thermalConfidence
) => {
  const riskScore =
    0.5 * parseFloat(facialMatching) +
    0.3 * parseFloat(licensePlateConfidence) +
    0.2 * parseFloat(thermalConfidence);
  return (1 - riskScore.toFixed(2) / 100).toFixed(2);
};

export const onChangeColor = (value) => {
  if (value >= 85) {
    return "green";
  } else if (value >= 70) {
    return "orange";
  } else {
    return "red";
  }
};

export const getRiskScoreColor = (value) => {
  if (value < 0.16) {
    return "green";
  } else if (value < 0.31) {
    return "orange";
  } else {
    return "red";
  }
};

export const getRiskScoreLabel = (value) => {
  if (value < 0.16) {
    return "Low";
  } else if (value < 0.31) {
    return "Medium";
  } else {
    return "High";
  }
};

export const getStatusRiskScoreColor = (value) => {
  if (value < 0.16) {
    return "success";
  } else if (value < 0.31) {
    return "warning";
  } else {
    return "error";
  }
};

export const ConfidenceColor = (value) => {
  if (value >= 85) {
    return "#ccffcc";
  } else if (value >= 70) {
    return "#ffffe0";
  } else {
    return "#ffd6cc";
  }
};
