import React from "react";
import { Button } from "@mui/material";

const NavItem = ({ icon: Icon, label, onClick }) => (
  <Button
    color="inherit"
    onClick={onClick}
    startIcon={<Icon />}
    sx={{ textTransform: "none" }}
  >
    {label}
  </Button>
);

export default NavItem;
