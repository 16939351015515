import React, { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Typography, Card, CardContent } from "@mui/material";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import {
  fetchTravelersThunk,
  addNewTaveler,
  addNewBorderAlert,
} from "../../redux";
import {
  setupTravelerWebSocket,
  setupAlertWebSocket,
} from "../../utils/websocket";
import { calculateTravelerStats } from "../../hooks/calculateTravelerStats";
import StatisticCard from "../../components/cards/StatisticsCard";
import BorderAlerts from "../../components/alerts/BorderAlerts";

// Icons
import PersonIcon from "@mui/icons-material/Person";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import SecurityIcon from "@mui/icons-material/Security";
import AssignmentIcon from "@mui/icons-material/Assignment";

const TravelersStatisticsPage = () => {
  const dispatch = useDispatch();
  const travelerData = useSelector((state) => state.travelers.travelerData);

  // Calculate Travelers Statistics
  const { stats } = useMemo(
    () => calculateTravelerStats(travelerData),
    [travelerData]
  );

  const borderAlertsState = useSelector((state) => state.borderAlerts.alerts);
  const [borderAlerts, setBorderAlerts] = useState(borderAlertsState);

  useEffect(() => {
    setBorderAlerts(borderAlertsState);
  }, [borderAlertsState]);

  // Fetch vehicles and setup WebSocket connections on mount
  useEffect(() => {
    dispatch(fetchTravelersThunk());

    // Set up WebSocket for travelers
    const cleanupTravelerWebSocket = setupTravelerWebSocket(
      (newVehicleData) => {
        dispatch(addNewTaveler(newVehicleData));
      }
    );

    // Set up WebSocket for alerts
    const cleanupAlertWebSocket = setupAlertWebSocket((newAlertData) => {
      dispatch(addNewBorderAlert(newAlertData));
    });

    // Cleanup function for both WebSockets
    return () => {
      cleanupTravelerWebSocket();
      cleanupAlertWebSocket();
    };
  }, [dispatch]);

  // Pie Chart Data for Verification Status
  const verificationPieData = [
    {
      name: "Verified",
      value: stats.dssVerified,
      color: "#4caf50",
    },
    {
      name: "Not Verified",
      value: stats.totalTravelers - stats.dssVerified,
      color: "#f44336",
    },
  ];

  // Pie Chart Data for Document Authentication
  const documentAuthPieData = [
    {
      name: "Authenticated",
      value: stats.documentsAuthenticated,
      color: "#2196f3",
    },
    {
      name: "Not Authenticated",
      value: stats.totalTravelers - stats.documentsAuthenticated,
      color: "#ff9800",
    },
  ];

  return (
    <Box sx={{ p: 3 }}>
      {borderAlerts.length >= 1 && <BorderAlerts alerts={borderAlerts} />}

      <Grid container spacing={3}>
        {/* Overview Statistics Cards */}
        <Grid item xs={12} md={3}>
          <StatisticCard
            title="Total Travelers"
            value={stats.totalTravelers}
            icon={PersonIcon}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatisticCard
            title="Verification Rate"
            value={`${(
              (stats.dssVerified / stats.totalTravelers) *
              100
            ).toFixed(2)}%`}
            icon={VerifiedUserIcon}
            color="success"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatisticCard
            title="Document Auth"
            value={`${(
              (stats.documentsAuthenticated / stats.totalTravelers) *
              100
            ).toFixed(2)}%`}
            icon={AssignmentIcon}
            color="info"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatisticCard
            title="Biometry Matched"
            value={`${(
              (stats.biometryMatched / stats.totalTravelers) *
              100
            ).toFixed(2)}%`}
            icon={SecurityIcon}
            color="secondary"
          />
        </Grid>

        {/* Verification Status Pie Chart */}
        <Grid item xs={12} md={6}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Verification Status
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={verificationPieData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {verificationPieData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Document Authentication Pie Chart */}
        <Grid item xs={12} md={6}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Document Authentication
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={documentAuthPieData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {documentAuthPieData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TravelersStatisticsPage;
