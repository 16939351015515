import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import LoggerUtils from "../../utils/logger/LoggerUtils";

export const fetchAccessToken = createAsyncThunk(
  "user/fetchAccessToken",
  async ({ username, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/token`,
        new URLSearchParams({
          client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
          grant_type: "password",
          username,
          password,
        }),
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      // Extract the access token
      const { access_token } = response.data;
      return access_token;
    } catch (error) {
      LoggerUtils.logError("fetching access token", error);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/me`,
        {},
        {
          headers: {
            accept: "application/json",
            authorization: `Bearer ${token}`,
          },
        }
      );
      const {
        exp,
        iat,
        azp,
        realm_access,
        name,
        preferred_username,
        given_name,
        family_name,
        email,
      } = response.data;
      const roles = realm_access.roles;
      return {
        exp,
        iat,
        azp,
        roles,
        name,
        preferred_username,
        given_name,
        family_name,
        email,
      };
    } catch (error) {
      LoggerUtils.logError("fetching user", error);
      return rejectWithValue(error.message);
    }
  }
);
