import { createSlice } from "@reduxjs/toolkit";
import {
  fetchTravelersThunk,
  deleteTravelerThunk,
  verifyTravelerThunk,
} from "../thunks/travelerThunks";

const travelerSlice = createSlice({
  name: "travelers",
  initialState: {
    travelerData: [],
    selectedTraveler: null,
    status: "idle",
    error: null,
  },
  reducers: {
    addNewTaveler: (state, action) => {
      state.travelerData.unshift(action.payload);
    },
    selectTraveler: (state, action) => {
      state.selectedTraveler = action.payload;
    },
    clearSelectedTraveler: (state) => {
      state.selectedTraveler = null;
    },
  },
  extraReducers: (builder) => {
    // fetchTravelersThunk
    builder
      .addCase(fetchTravelersThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTravelersThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.travelerData = action.payload;
      })
      .addCase(fetchTravelersThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // deleteTravelerThunk
      .addCase(deleteTravelerThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteTravelerThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.travelerData = state.travelerData.filter(
          (vehicle) => vehicle.id !== action.payload
        );
      })
      .addCase(deleteTravelerThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // verifyTravelerThunk
      .addCase(verifyTravelerThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyTravelerThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        const verifiedTraveler = state.travelerData.find(
          (vehicle) => vehicle.id === action.payload.id
        );
        if (verifiedTraveler) {
          verifiedTraveler.verified = true;
          verifiedTraveler.verificationMessage =
            action.payload.verificationMessage;
        }
      })
      .addCase(verifyTravelerThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { addNewTaveler, selectTraveler, clearSelectedTraveler } =
  travelerSlice.actions;
export default travelerSlice.reducer;
