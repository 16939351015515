import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import TextConstants from "../../utils/constants/TextConstants";
import LoggerUtils from "../../utils/logger/LoggerUtils";
import { preprocessVehicleData } from "../../utils/helpers";

export const fetchVehiclesThunk = createAsyncThunk(
  "vehicles/fetchVehicles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(TextConstants.ALL_VEHICLES_ENDPOINT);
      const processedVehicles = response.data.map(preprocessVehicleData);
      return processedVehicles;
    } catch (error) {
      LoggerUtils.logError("fetching vehicles", error);
      return rejectWithValue(error.message);
    }
  }
);

export const deleteVehicleThunk = createAsyncThunk(
  "vehicles/deleteVehicle",
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(TextConstants.VEHICLES_ENDPOINT, {
        params: { id: id },
      });
      return id;
    } catch (error) {
      LoggerUtils.logError("deleting vehicle", error);
      return rejectWithValue(error.message);
    }
  }
);

export const verifyVehicleThunk = createAsyncThunk(
  "vehicles/verifyVehicle",
  async ({ id, verificationMessage }, { rejectWithValue }) => {
    try {
      await axios.put(TextConstants.VEHICLES_VERIFICATION_ENDPOINT, null, {
        params: { id: id, message: verificationMessage },
        headers: {
          accept: "application/json",
        },
      });
      return {
        id: id,
        verificationMessage: verificationMessage,
      };
    } catch (error) {
      LoggerUtils.logError("verifying vehicle", error);
      return rejectWithValue(error.message);
    }
  }
);
