import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import TextConstants from "../../utils/constants/TextConstants";
import LoggerUtils from "../../utils/logger/LoggerUtils";
import { preprocessTravelerData } from "../../utils/helpers";

export const fetchTravelersThunk = createAsyncThunk(
  "travelers/fetchTravelers",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(TextConstants.ALL_TRAVELERS_ENDPOINT);
      const processedTravelers = response.data.map(preprocessTravelerData);
      return processedTravelers;
    } catch (error) {
      LoggerUtils.logError("fetching travelers", error);
      return rejectWithValue(error.message);
    }
  }
);

export const deleteTravelerThunk = createAsyncThunk(
  "travelers/deleteTraveler",
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(TextConstants.TRAVELERS_ENDPOINT, {
        params: { id: id },
      });
      return id;
    } catch (error) {
      LoggerUtils.logError("deleting traveler", error);
      return rejectWithValue(error.message);
    }
  }
);

export const verifyTravelerThunk = createAsyncThunk(
  "travelers/verifyTraveler",
  async ({ id, verificationMessage }, { rejectWithValue }) => {
    try {
      await axios.put(TextConstants.TRAVELERS_VERIFICATION_ENDPOINT, null, {
        params: { id: id, message: verificationMessage },
        headers: {
          accept: "application/json",
        },
      });
      return {
        id: id,
        verificationMessage: verificationMessage,
      };
    } catch (error) {
      LoggerUtils.logError("verifying traveler", error);
      return rejectWithValue(error.message);
    }
  }
);
