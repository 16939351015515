import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, Paper, Snackbar, Alert, Grid2 } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchVehiclesThunk,
  deleteVehicleThunk,
  selectVehicle,
  verifyVehicleThunk,
  addNewVehicle,
  addNewBorderAlert,
} from "../../../redux";
import DeleteDialog from "./components/DeleteDialog";
import VerificationDialog from "./components/VerificationDialog";
import { getColumns } from "./components/columnsConfig";
import {
  setupVehicleWebSocket,
  setupAlertWebSocket,
} from "../../../utils/websocket";
import MessageUtils from "../../../utils/messages/MessageUtils";
import LoggerUtils from "../../../utils/logger/LoggerUtils";
import TextConstants from "../../../utils/constants/TextConstants";
import { useIsMobileImage } from "../../../hooks/useMediaQueries";
import BorderAlerts from "../../../components/alerts/BorderAlerts";
import Analysis from "./components/Analysis";

const VehiclesDashboard = () => {
  const dispatch = useDispatch();
  const vehicleData = useSelector((state) => state.vehicles.vehicleData);

  const selectedVehicle = useSelector(
    (state) => state.vehicles.selectedVehicle
  );

  const [vehicleToDelete, setVehicleToDelete] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [verificationDialogOpen, setVerificationDialogOpen] = useState(false);
  const [verificationFormData, setVerificationFormData] = useState({
    id: "",
    licensePlate: "",
    verificationMessage: "",
  });
  const [verificationErrors, setVerificationErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const isMobile = useIsMobileImage();

  // Ref for scrolling to the selected vehicle section
  const selectedVehicleRef = useRef(null);
  const borderAlertsState = useSelector((state) => state.borderAlerts.alerts);
  const [borderAlerts, setBorderAlerts] = useState(borderAlertsState);

  useEffect(() => {
    setBorderAlerts(borderAlertsState);
  }, [borderAlertsState]);

  // Fetch vehicles and setup WebSocket connections on mount
  useEffect(() => {
    dispatch(fetchVehiclesThunk());

    // Set up WebSocket for vehicles
    const cleanupVehicleWebSocket = setupVehicleWebSocket((newVehicleData) => {
      dispatch(addNewVehicle(newVehicleData));
    });

    // Set up WebSocket for alerts
    const cleanupAlertWebSocket = setupAlertWebSocket((newAlertData) => {
      dispatch(addNewBorderAlert(newAlertData));
    });

    // Cleanup function for both WebSockets
    return () => {
      cleanupVehicleWebSocket();
      cleanupAlertWebSocket();
    };
  }, [dispatch]);

  // Scroll to selected vehicle section when selectedVehicle changes
  useEffect(() => {
    if (selectedVehicle && selectedVehicleRef.current) {
      selectedVehicleRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedVehicle]);

  // Snackbar handlers
  const handleSnackbarOpen = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason !== "clickaway") {
      setSnackbarOpen(false);
    }
  };

  // Delete vehicle handlers
  const handleOpenDeleteDialog = (vehicle) => {
    setVehicleToDelete(vehicle);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setVehicleToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (vehicleToDelete) {
      try {
        await dispatch(deleteVehicleThunk(vehicleToDelete.id));
        handleSnackbarOpen(
          MessageUtils.SUCCESS_VEHICLE_DELETED(vehicleToDelete.licensePlate)
        );
        if (selectedVehicle?.id === vehicleToDelete.id) {
          dispatch(selectVehicle(null));
        }
      } catch (error) {
        LoggerUtils.logError(
          `deleting the vehicle ${vehicleToDelete.id}`,
          error
        );
        handleSnackbarOpen(
          MessageUtils.ERROR_DELETING_VEHICLE(vehicleToDelete.licensePlate),
          "error"
        );
      } finally {
        handleCloseDeleteDialog();
      }
    }
  };

  // Info display handler
  const handleShowInfo = (vehicle) => {
    dispatch(selectVehicle(vehicle));
  };

  // Verification handlers
  const handleVerification = (vehicle) => {
    setVerificationFormData({
      id: vehicle.id,
      licensePlate: vehicle.licensePlate,
      verificationMessage: "",
    });
    setVerificationDialogOpen(true);
  };

  const handleVerificationDialogClose = () => {
    setVerificationDialogOpen(false);
    setVerificationFormData({
      id: "",
      licensePlate: "",
      verificationMessage: "",
    });
    setVerificationErrors({});
  };

  const handleVerificationChange = (event) => {
    const { name, value } = event.target;
    setVerificationFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleVerificationSubmit = async () => {
    try {
      await dispatch(
        verifyVehicleThunk({
          id: verificationFormData.id,
          verificationMessage: verificationFormData.verificationMessage,
        })
      );
      handleSnackbarOpen(
        MessageUtils.SUCCESS_VEHICLE_VERIFICATION(
          verificationFormData.licensePlate
        )
      );
    } catch (error) {
      LoggerUtils.logError(
        `veryfing the vehicle ${verificationFormData.id}`,
        error
      );
      handleSnackbarOpen(
        MessageUtils.ERROR_VERIFYING_VEHICLE(verificationFormData.licensePlate),
        "error"
      );
    } finally {
      handleVerificationDialogClose();
    }
  };

  const columns = getColumns(
    handleVerification,
    handleOpenDeleteDialog,
    handleShowInfo
  );

  return (
    <Box sx={{ width: "100%" }}>
      {borderAlerts.length >= 1 && <BorderAlerts alerts={borderAlerts} />}

      {/* Real-time Vehicles Table */}
      {/* <Grid2 container spacing={3}> */}
      <Grid2 xs={12} sm={50} md={isMobile ? 6 : 3}>
        <Paper elevation={3} sx={{ p: 3, mb: 3, mt: 3, width: "100%" }}>
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{
                fontWeight: "medium",
                fontFamily: "BlinkMacSystemFont",
                color: "primary.main",
              }}
            >
              {TextConstants.REAL_TIME_VEHICLES_HEADER}
            </Typography>
            <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
              {TextConstants.REAL_TIME_VEHICLES_SUBTITLE}
            </Typography>
          </Box>
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={vehicleData}
              columns={columns}
              disableRowSelectionOnClick
              disableVirtualization
            />
          </Box>
        </Paper>
      </Grid2>

      {/* Display selected vehicle details */}
      {selectedVehicle && (
        <Paper sx={{ mt: 6, p: 2 }} ref={selectedVehicleRef}>
          {/* {selectedVehicle.verificationMessage && (
            <Typography variant="body1" color="text.secondary" gutterBottom>
              Verification: {selectedVehicle.verificationMessage}
            </Typography>
          )} */}
          <Analysis data={selectedVehicle} />
        </Paper>
      )}

      {/* Delete vehicle dialog */}
      <DeleteDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        vehicleToDelete={vehicleToDelete}
        onConfirm={handleConfirmDelete}
      />

      {/* Verification dialog */}
      <VerificationDialog
        open={verificationDialogOpen}
        onClose={handleVerificationDialogClose}
        formData={verificationFormData}
        onChange={handleVerificationChange}
        onSubmit={handleVerificationSubmit}
        errors={verificationErrors}
        isFormValid
      />

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default VehiclesDashboard;
