// src/store/borderAlertsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const borderAlertsSlice = createSlice({
  name: "borderAlerts",
  initialState: {
    crowd_counting: [],
    alerts: [], // Array to store alert messages
    isLoading: false,
    error: null,
  },
  reducers: {
    setBorderAlerts(state, action) {
      state.alerts = action.payload;
    },
    addNewBorderAlert(state, action) {
      const existingIndex = state.alerts.findIndex(
        (alert) => alert.device === action.payload.device
      );

      if (existingIndex !== -1) {
        // Replace the existing alert with the new one
        state.alerts[existingIndex] = action.payload;
      } else {
        // Add the new alert at the beginning
        state.alerts.unshift(action.payload);
      }
    },
    removeBorderAlert(state, action) {
      // Filter out the alert with the specified ID
      state.alerts.splice(action.payload, 1);
    },
    clearBorderAlerts(state) {
      state.alerts = [];
    },
    addCrowdCountingAlerts(state, action) {
      const existingIndex = state.crowd_counting.findIndex(
        (alert) => alert.device === action.payload.device
      );

      if (existingIndex !== -1) {
        // Replace the existing alert with the new one
        state.crowd_counting[existingIndex] = action.payload;
      } else {
        // Add the new alert at the beginning
        state.crowd_counting.unshift(action.payload);
      }
    },
  },
});

export const {
  setBorderAlerts,
  addNewBorderAlert,
  removeBorderAlert,
  clearBorderAlerts,
  addCrowdCountingAlerts,
} = borderAlertsSlice.actions;

export default borderAlertsSlice.reducer;
