import React from "react";
import { Typography, Box } from "@mui/material";
import TextConstants from "../../../utils/constants/TextConstants";

export const TitleSection = () => (
  <Typography
    variant="h6"
    component="div"
    sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
  >
    <Box
      component="img"
      src={`${process.env.PUBLIC_URL}/odysseus.png`}
      alt={TextConstants.APP_TITLE}
      sx={{ height: 32, width: 32, mr: 1 }}
    />
    {TextConstants.APP_TITLE}
  </Typography>
);
