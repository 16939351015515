import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
// import InfoIcon from "@mui/icons-material/Info";
import BarChartIcon from "@mui/icons-material/BarChart";
import { AccountCircle } from "@mui/icons-material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import TextConstants from "../../../utils/constants/TextConstants";

export const DrawerContent = ({ toggleDrawer, onLogout, navigate }) => {
  const navItems = [
    { icon: HomeIcon, label: TextConstants.DASHBOARD_BUTTON, path: "/" },
    {
      icon: BarChartIcon,
      label: TextConstants.STATISTICS_BUTTON,
      path: "/statistics",
    },
    // { icon: InfoIcon, label: TextConstants.ABOUT_BUTTON, path: "/about" },
    {
      icon: AccountCircle,
      label: TextConstants.PROFILE_BUTTON,
      path: "/profile",
    },
    {
      icon: ExitToAppIcon,
      label: TextConstants.LOGOUT_BUTTON,
      onClick: () => {
        onLogout();
        navigate("/");
      },
    },
  ];

  return (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
        <IconButton onClick={toggleDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <List>
        {navItems.map((item) => (
          <ListItem
            button
            key={item.label}
            onClick={() => {
              item.onClick ? item.onClick() : navigate(item.path);
              toggleDrawer(false)();
            }}
          >
            <ListItemIcon>
              <item.icon />
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
