import React, { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Typography, Card, CardContent } from "@mui/material";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import {
  fetchVehiclesThunk,
  addNewVehicle,
  addNewBorderAlert,
} from "../../redux";
import {
  setupVehicleWebSocket,
  setupAlertWebSocket,
} from "../../utils/websocket";
import { calculateVehicleStats } from "../../hooks/calculateVehicleStats";
import StatisticCard from "../../components/cards/StatisticsCard";
import BorderAlerts from "../../components/alerts/BorderAlerts";

// Icons
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import WarningIcon from "@mui/icons-material/Warning";
import CameraIcon from "@mui/icons-material/Camera";

const VehiclesStatisticsPage = () => {
  const dispatch = useDispatch();

  const vehicleData = useSelector((state) => state.vehicles.vehicleData);

  // Calculate Vehicles Statistics
  const stats = useMemo(
    () => calculateVehicleStats(vehicleData),
    [vehicleData]
  );

  const borderAlertsState = useSelector((state) => state.borderAlerts.alerts);
  const [borderAlerts, setBorderAlerts] = useState(borderAlertsState);

  useEffect(() => {
    setBorderAlerts(borderAlertsState);
  }, [borderAlertsState]);

  // Fetch vehicles and setup WebSocket connections on mount
  useEffect(() => {
    dispatch(fetchVehiclesThunk());

    // Set up WebSocket for vehicles
    const cleanupVehicleWebSocket = setupVehicleWebSocket((newVehicleData) => {
      dispatch(addNewVehicle(newVehicleData));
    });

    // Set up WebSocket for alerts
    const cleanupAlertWebSocket = setupAlertWebSocket((newAlertData) => {
      dispatch(addNewBorderAlert(newAlertData));
    });

    // Cleanup function for both WebSockets
    return () => {
      cleanupVehicleWebSocket();
      cleanupAlertWebSocket();
    };
  }, [dispatch]);

  // Pie Chart Data for Verification Status
  const verificationPieData = [
    {
      name: "Verified",
      value: stats.verifiedVehicles,
      color: "#4caf50",
    },
    {
      name: "Not Verified",
      value: stats.totalVehicles - stats.verifiedVehicles,
      color: "#f44336",
    },
  ];

  // Pie Chart Data for Car Types
  const carTypesPieData = Object.entries(stats.carTypes).map(
    ([name, value]) => ({
      name,
      value,
      color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    })
  );

  return (
    <Box sx={{ p: 3 }}>
      {borderAlerts.length >= 1 && <BorderAlerts alerts={borderAlerts} />}

      <Grid container spacing={3}>
        {/* Overview Statistics Cards */}
        <Grid item xs={12} md={3}>
          <StatisticCard
            title="Total Vehicles"
            value={stats.totalVehicles}
            icon={DirectionsCarIcon}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatisticCard
            title="Verification Rate"
            value={`${(
              (stats.verifiedVehicles / stats.totalVehicles) *
              100
            ).toFixed(2)}%`}
            icon={VerifiedUserIcon}
            color="success"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatisticCard
            title="Avg Risk Score"
            value={(stats.averageRiskScore * 100).toFixed(2)}
            icon={WarningIcon}
            color="warning"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatisticCard
            title="LPR Confidence"
            value={`${(Math.min(...stats.licensePlateConfidence) * 100).toFixed(
              2
            )}%`}
            icon={CameraIcon}
            color="info"
          />
        </Grid>

        {/* Verification Status Pie Chart */}
        <Grid item xs={12} md={6}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Verification Status
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={verificationPieData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {verificationPieData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Car Types Pie Chart */}
        <Grid item xs={12} md={6}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Car Types Distribution
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={carTypesPieData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {carTypesPieData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Additional Insights */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Additional Insights
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle1">
                    Car Colors:
                    {Object.entries(stats.carColors).map(([color, count]) => (
                      <Box
                        key={color}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          my: 1,
                        }}
                      >
                        <Typography>{color}</Typography>
                        <Typography>{count}</Typography>
                      </Box>
                    ))}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle1">
                    Thermal Confidence:
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        my: 1,
                      }}
                    >
                      <Typography>Min</Typography>
                      <Typography>
                        {(Math.min(...stats.thermalConfidence) * 100).toFixed(
                          2
                        )}
                        %
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        my: 1,
                      }}
                    >
                      <Typography>Max</Typography>
                      <Typography>
                        {(Math.max(...stats.thermalConfidence) * 100).toFixed(
                          2
                        )}
                        %
                      </Typography>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle1">
                    Risk Score Analysis:
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        my: 1,
                      }}
                    >
                      <Typography>Min</Typography>
                      <Typography>
                        {(
                          Math.min(...vehicleData.map((v) => v.riskScore)) * 100
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        my: 1,
                      }}
                    >
                      <Typography>Max</Typography>
                      <Typography>
                        {(
                          Math.max(...vehicleData.map((v) => v.riskScore)) * 100
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VehiclesStatisticsPage;
