import React from "react";
import { IconButton, Box, Tooltip, Chip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import VerifiedIcon from "@mui/icons-material/Verified";
import TextConstants from "../../../../utils/constants/TextConstants";
import {
  getStatusRiskScoreColor,
  getRiskScoreLabel,
} from "../../../../utils/helpers";

export const renderTooltip = (
  title,
  content,
  popperOptions = { offset: [0, -8] }
) => (
  <Tooltip
    title={title}
    placement="top"
    arrow
    slotProps={{
      popper: {
        modifiers: [
          {
            name: "offset",
            options: popperOptions,
          },
          {
            name: "arrow",
            options: {
              padding: 5,
            },
          },
        ],
      },
    }}
  >
    {content}
  </Tooltip>
);

export const renderRiskScore = (params) => (
  <Chip
    label={`${(params.value * 100).toFixed(1)}%`}
    color={getStatusRiskScoreColor(params.value)}
    size="small"
  />
);
export const renderRiskScoreLabel = (params) => (
  <Chip
    label={getRiskScoreLabel(params.row.riskScore)}
    color={getStatusRiskScoreColor(params.row.riskScore)}
    size="small"
  />
);

export const renderVerificationStatus = (params) => (
  <Chip
    icon={params.value ? <VerifiedIcon /> : undefined}
    label={
      params.value ? TextConstants.VERIFIED : TextConstants.PENDING_VERIFICATION
    }
    color={params.value ? "success" : "warning"}
    size="small"
    variant={params.value ? "filled" : "outlined"}
  />
);

export const renderActions = (
  params,
  handleOpenDeleteDialog,
  handleShowInfo,
  handleVerification
) => (
  <Box display="flex" justifyContent="space-between">
    {renderTooltip(
      TextConstants.VIEW_DETAILS,
      <IconButton
        color="primary"
        onClick={() => handleShowInfo(params.row)}
        size="small"
      >
        <InfoIcon />
      </IconButton>
    )}
    {renderTooltip(
      params.row.verified
        ? TextConstants.VERIFIED
        : TextConstants.VERIFY_VEHICLE,
      <span style={{ display: "inline-block" }}>
        <IconButton
          sx={{ color: "#FFA726" }}
          onClick={() => !params.row.verified && handleVerification(params.row)}
          size="small"
          disabled={params.row.verified}
        >
          <VerifiedIcon />
        </IconButton>
      </span>
    )}
    {renderTooltip(
      TextConstants.DELETE_VEHICLE,
      <IconButton
        color="error"
        onClick={() => handleOpenDeleteDialog(params.row)}
        size="small"
      >
        <DeleteIcon />
      </IconButton>
    )}
  </Box>
);
