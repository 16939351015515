export const calculateVehicleStats = (data = []) => {
  const totalVehicles = data.length || 0;
  const verifiedVehicles =
    data.filter((vehicle) => vehicle?.verified).length || 0;
  const licensePlateConfidence = data.map((v) => v?.lprConfidence || 0);
  const thermalConfidence = data.map((v) => v?.thermalConfidence || 0);
  const averageRiskScore =
    totalVehicles > 0
      ? data.reduce((sum, vehicle) => sum + (vehicle?.riskScore || 0), 0) /
        totalVehicles
      : 0;
  const carColors = data.reduce((acc, vehicle) => {
    const color = vehicle?.carColor || "Unknown";
    acc[color] = (acc[color] || 0) + 1;
    return acc;
  }, {});
  const carTypes = data.reduce((acc, vehicle) => {
    const type = vehicle?.carType || "Unknown";
    acc[type] = (acc[type] || 0) + 1;
    return acc;
  }, {});

  return {
    totalVehicles,
    verifiedVehicles,
    licensePlateConfidence,
    thermalConfidence,
    averageRiskScore,
    carColors,
    carTypes,
  };
};
