import React, { useState } from "react";
import { Box, Paper, Container, Tabs, Tab } from "@mui/material";
import { useDispatch } from "react-redux";
import { People, Commute } from "@mui/icons-material";
import { clearSelectedVehicle } from "../../redux";
import VehiclesStatistics from "./VehiclesStatistics";
import TravelersStatistics from "./TravelersStatistics";

const StatisticsDashboard = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderContent = () => {
    dispatch(clearSelectedVehicle());
    if (value === 0) {
      return <VehiclesStatistics />;
    } else {
      return <TravelersStatistics />;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Container maxWidth="xl" sx={{ mt: 0 }}>
        <Paper sx={{ mb: 1, mt: 2 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab icon={<Commute />} label="Vehicles" />
            <Tab icon={<People />} label="Travelers" />
          </Tabs>
        </Paper>
        {renderContent()}
      </Container>
    </Box>
  );
};

export default StatisticsDashboard;
