import React, { useState } from "react";
import {
  Avatar,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Box,
  Typography,
  Container,
  CssBaseline,
  Paper,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import ColorConstants from "../../utils/constants/ColorConstants";
import TextConstants from "../../utils/constants/TextConstants";
import { useDispatch } from "react-redux";
import { fetchAccessToken, fetchUser } from "../../redux/thunks/userThunks";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);

    try {
      const resultToken = await dispatch(
        fetchAccessToken({ username: email, password })
      );
      const token = resultToken.payload;
      const resultUser = await dispatch(fetchUser(token));

      if (resultToken?.error || resultUser?.error) {
        setError("Authentication failed. Please try again.");
      } else {
        if (rememberMe) {
          localStorage.setItem("username", email);
          localStorage.setItem("password", password);
        } else {
          sessionStorage.setItem("username", email);
          sessionStorage.setItem("password", password);
        }
      }
    } catch (err) {
      const errorMessage =
        err?.message || "Authentication failed. Please try again.";
      setError(errorMessage); // Display error message to the user
      console.error("Error during login:", err); // Log error for debugging
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          marginTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bgcolor: "background.paper",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/odysseus.png`}
            alt="EU Project Logo"
            sx={{ height: 64, mb: 2 }}
          />
          <Typography
            component="h1"
            variant="h4"
            sx={{
              fontWeight: "bold",
              mb: 3,
              color: ColorConstants.EU_BLUE_COLOR,
            }}
          >
            {TextConstants.APP_TITLE}
          </Typography>
          <Avatar sx={{ m: 1, bgcolor: ColorConstants.EU_BLUE_COLOR }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h2" variant="h5" sx={{ mb: 3 }}>
            {TextConstants.SIGN_IN_BUTTON}
          </Typography>

          {/* Show error message if there is an authentication error */}
          {error && (
            <Alert severity="error" sx={{ width: "100%", mb: 2 }}>
              {error}
            </Alert>
          )}

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, width: "100%" }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
              }
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                bgcolor: ColorConstants.EU_BLUE_COLOR,
                "&:hover": { bgcolor: ColorConstants.NAVY_BLUE_COLOR },
              }}
            >
              {TextConstants.SIGN_IN_BUTTON}
            </Button>
            <Link
              href="#"
              variant="body2"
              sx={{ color: ColorConstants.EU_BLUE_COLOR }}
            >
              {TextConstants.FORGOT_PASSWORD_BUTTON}
            </Link>
          </Box>
        </Box>
      </Paper>
      <Box sx={{ mt: 2, textAlign: "center" }}>
        <Typography variant="body2" color="text.secondary">
          © {new Date().getFullYear()} Odysseus DSS. All rights reserved.
        </Typography>
      </Box>
    </Container>
  );
};

export default SignIn;
