import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Alert,
  AlertTitle,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Person as PersonIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";
import StatusChip from "../../../../components/chips/StatusChip";

export const VehiclePassengers = ({ passengers = [] }) => {
  // Format date helper function
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // Base64 image validation helper
  const isValidBase64 = (str) => {
    try {
      return str && typeof str === "string" && str.includes(",");
    } catch (e) {
      return false;
    }
  };

  // Get proper image source
  const getImageSource = (base64String) => {
    if (!base64String) return null;
    if (base64String.startsWith("data:image")) {
      return base64String;
    }
    return `data:image/png;base64,${base64String}`;
  };

  if (!passengers || passengers.length === 0) {
    return (
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Passengers
          </Typography>
          <Typography variant="body2" color="text.secondary">
            No passengers available.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
          <PersonIcon />
          <Typography variant="h6">Passengers ({passengers.length})</Typography>
        </Box>

        <Grid container spacing={2}>
          {passengers.map((passenger, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Card variant="outlined">
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      mb: 2,
                    }}
                  >
                    <Typography variant="h6">
                      {passenger.document.givenName}{" "}
                      {passenger.document.surname}
                    </Typography>
                    <StatusChip
                      verified={passenger.dssValidationStatus.verified}
                      onClick={() =>
                        window.open(passenger.dssValidationStatus.url, "_blank")
                      }
                    />
                  </Box>

                  {/* Document Image */}
                  {passenger.biometrics.documentImage && (
                    <Box
                      sx={{
                        height: 200,
                        width: "100%",
                        mb: 2,
                        borderRadius: 1,
                        bgcolor: "grey.100",
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {isValidBase64(passenger.documentImage) ? (
                        <Box
                          component="img"
                          src={getImageSource(passenger.documentImage)}
                          alt="Travel Document"
                          sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <DescriptionIcon
                          sx={{ fontSize: 48, color: "grey.400" }}
                        />
                      )}
                    </Box>
                  )}

                  {/* Basic Information */}
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      color="text.secondary"
                      variant="caption"
                      display="block"
                    >
                      Document Type
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {passenger.document.type}
                    </Typography>

                    {passenger.document.number && (
                      <Box sx={{ mt: 1 }}>
                        <Typography
                          color="text.secondary"
                          variant="caption"
                          display="block"
                        >
                          Document Number
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {passenger.document.number}
                        </Typography>
                      </Box>
                    )}

                    {passenger.document.dateOfBirth && (
                      <Box sx={{ mt: 1 }}>
                        <Typography
                          color="text.secondary"
                          variant="caption"
                          display="block"
                        >
                          Date of Birth
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {formatDate(passenger.document.dateOfBirth)}
                        </Typography>
                      </Box>
                    )}

                    {passenger.document.nationality && (
                      <Box sx={{ mt: 1 }}>
                        <Typography
                          color="text.secondary"
                          variant="caption"
                          display="block"
                        >
                          Nationality
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {passenger.document.nationality}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  {/* Verification Status */}
                  {passenger.verificationStatus && (
                    <Alert
                      severity={
                        passenger.verificationStatus.documentAuthenticated
                          ? "success"
                          : "error"
                      }
                      icon={
                        passenger.verificationStatus.documentAuthenticated ? (
                          <CheckCircleIcon />
                        ) : (
                          <CancelIcon />
                        )
                      }
                    >
                      <AlertTitle>Verification Status</AlertTitle>
                      {passenger.verificationStatus.message}
                    </Alert>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
