import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
import { store } from "../src/redux";
import App from "./App";
import ColorConstants from "./utils/constants/ColorConstants";

// Create a theme
const theme = createTheme({
  // typography: {
  //   fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  // },
  palette: {
    background: {
      default: ColorConstants.LIGHT_GREY,
    },
    primary: {
      main: ColorConstants.EU_BLUE_COLOR,
    },
  },
});

// Get the root element from the DOM
const container = document.getElementById("root");

// Create a root
const root = createRoot(container);

// Render the application using createRoot
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Provider>
);
