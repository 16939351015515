import {
  renderActions,
  renderRiskScore,
  renderRiskScoreLabel,
  renderVerificationStatus,
} from "./renderUtils";

export const getColumns = (
  handleVerification,
  handleOpenDeleteDialog,
  handleShowInfo
) => [
  {
    field: "licensePlate",
    headerName: "License Plate",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "lprConfidence",
    headerName: "License Plate Confidence",
    type: "number",
    flex: 1,
    width: 130,
    valueFormatter: (value) => `${(value * 100).toFixed(1)}%`,
  },
  // {
  //   field: "facialMatchScore",
  //   headerName: "Facial Match",
  //   type: "number",
  //   width: 130,
  //   valueFormatter: (value) => `${(value * 100).toFixed(1)}%`,
  // },
  {
    field: "thermalConfidence",
    headerName: "Thermal Confidence",
    type: "number",
    width: 150,
    valueFormatter: (value) => `${(value * 100).toFixed(1)}%`,
  },
  {
    field: "riskScore",
    headerName: "Risk Score",
    width: 120,
    renderCell: renderRiskScore,
  },
  {
    field: "riskScoreLabel",
    headerName: "Risk Assessment",
    width: 130,
    renderCell: renderRiskScoreLabel,
  },
  {
    field: "verified",
    headerName: "Status",
    width: 120,
    renderCell: renderVerificationStatus,
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 150,
    renderCell: (params) =>
      renderActions(
        params,
        handleOpenDeleteDialog,
        handleShowInfo,
        handleVerification
      ),
    sortable: false,
    filterable: false,
  },
];

export default getColumns;
