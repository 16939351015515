export const fetchTraveler = async (id) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/travelers/?id=${id}`
    );
    if (!response.ok) throw new Error("Failed to fetch data");
    const data = await response.json();
    return { data, error: null };
  } catch (err) {
    return { data: null, error: err.message };
  }
};
