import React, { useState } from "react";
import {
  Container,
  Paper,
  Typography,
  Grid2,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Box,
  Divider,
  Dialog,
  DialogContent,
} from "@mui/material";
import {
  DirectionsCar,
  ColorLens,
  CropFree,
  Assessment,
} from "@mui/icons-material";
import { VehiclePassengers } from "./VehiclePassengers";

const VehicleAnalysis = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  const handleOpen = (image) => {
    setModalImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setModalImage(null);
    setOpen(false);
  };
  return (
    <>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        {/* Header with overall confidence */}
        <Paper
          elevation={3}
          sx={{
            p: 3,
            mb: 3,
            bgcolor: "primary.light",
            color: "primary.contrastText",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Assessment />
              <Typography variant="h5">Vehicle Analysis Report</Typography>
            </Box>
            <Chip
              label={`Risk Score: ${Math.round(data.riskScore * 100)}%`}
              color="primary"
              variant="outlined"
              sx={{ bgcolor: "white" }}
            />
          </Box>
        </Paper>

        {/* License Plate Info */}
        <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                variant="h4"
                sx={{ fontFamily: "monospace", fontWeight: "bold" }}
              >
                {data.licensePlate}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Registration: {data.registrationNumber}
              </Typography>
            </Box>
            <CropFree sx={{ fontSize: 40, color: "text.secondary" }} />
          </Box>
        </Paper>

        <Grid2 container spacing={3}>
          {/* Vehicle Type Analysis */}
          <Grid2 item xs={12} md={6}>
            <Card>
              <CardHeader
                avatar={<DirectionsCar />}
                title="Vehicle Classification"
                subheader={
                  <Chip
                    label={data.carType}
                    color="primary"
                    size="small"
                    sx={{ mt: 1 }}
                  />
                }
              />
              <CardContent>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  Classification Reasoning:
                </Typography>
                <Paper variant="outlined" sx={{ p: 2, bgcolor: "grey.50" }}>
                  {data.typeReasoning
                    .trim()
                    .replace(/\/\/n/g, "/n")
                    .replace("/n", "")
                    .split("-")
                    .map((reason, index) => (
                      <Typography
                        key={index}
                        variant="body2"
                        sx={{
                          mb: 1,
                          fontWeight: index !== 0 ? "normal" : "bold",
                        }}
                      >
                        {index !== 0 && reason[0] === reason[0].toUpperCase()
                          ? "- " + reason.trim()
                          : reason.trim()}
                      </Typography>
                    ))}
                </Paper>
              </CardContent>
            </Card>
          </Grid2>

          {/* Color Analysis */}
          <Grid2 item xs={12} md={6}>
            <Card>
              <CardHeader
                avatar={<ColorLens />}
                title="Color Analysis"
                subheader={
                  <Chip
                    label={data.carColor}
                    color="primary"
                    size="small"
                    sx={{ mt: 1 }}
                  />
                }
              />
              <CardContent>
                <Chip
                  label={`Color Confidence: ${Math.round(
                    data.colorConfidence * 100
                  )}%`}
                  color="primary"
                  variant="outlined"
                  sx={{ bgcolor: "white" }}
                />
              </CardContent>
            </Card>
          </Grid2>

          {/* Image Analysis */}
          <Grid2 item xs={12}>
            <Card>
              <CardHeader
                title="XAI Visualization"
                subheader="Comparing original and XAI-enhanced license plate images"
              />
              <Divider />
              <CardContent>
                <Grid2 container spacing={2}>
                  <Grid2 item xs={12} md={6}>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                      Original Image
                    </Typography>
                    <Paper
                      variant="outlined"
                      sx={{
                        aspectRatio: "16/9",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={`data:image/png;base64,${data.licensePlateImage}`}
                        alt="Original License Plate"
                        style={{
                          width: "50",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpen(
                            `data:image/png;base64,${data.licensePlateImage}`
                          )
                        }
                      />
                    </Paper>
                  </Grid2>
                  <Grid2 item xs={12} md={6}>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                      XAI Enhanced View
                    </Typography>
                    <Paper
                      variant="outlined"
                      sx={{
                        aspectRatio: "16/9",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={`data:image/png;base64,${data.xaiLicensePlateImage}`}
                        alt="XAI Enhanced License Plate"
                        style={{
                          width: "50",
                          // height: "100%",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpen(
                            `data:image/png;base64,${data.xaiLicensePlateImage}`
                          )
                        }
                      />
                    </Paper>
                  </Grid2>
                </Grid2>
                <Grid2 container spacing={2}>
                  <Grid2 item xs={12} md={6}>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                      Thermal Image
                    </Typography>
                    <Paper
                      variant="outlined"
                      sx={{
                        aspectRatio: "16/9",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={`data:image/png;base64,${data.thermalImage}`}
                        alt="Thermal"
                        style={{
                          width: "50",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpen(
                            `data:image/png;base64,${data.thermalImage}`
                          )
                        }
                      />
                    </Paper>
                  </Grid2>
                  <Grid2 item xs={12} md={6}>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                      XRAY Image
                    </Typography>
                    <Paper
                      variant="outlined"
                      sx={{
                        aspectRatio: "16/9",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={`data:image/png;base64,${data.xrayImage}`}
                        alt="XRAY"
                        style={{
                          width: "50",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpen(`data:image/png;base64,${data.xrayImage}`)
                        }
                      />
                    </Paper>
                  </Grid2>
                </Grid2>
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>
        {data?.passengers && data.passengers.length > 0 && (
          <VehiclePassengers passengers={data.passengers} />
        )}
      </Container>

      {/* Modal for larger image */}
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogContent>
          {modalImage && (
            <img
              src={modalImage}
              alt="Enlarged"
              style={{ width: "100%", height: "auto" }}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VehicleAnalysis;
