import React from "react";
import { IconButton, Box, Tooltip } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TextConstants from "../../../../utils/constants/TextConstants";

export const renderTooltip = (
  title,
  content,
  popperOptions = { offset: [0, -8] }
) => (
  <Tooltip
    title={title}
    placement="top"
    arrow
    slotProps={{
      popper: {
        modifiers: [
          {
            name: "offset",
            options: popperOptions,
          },
          {
            name: "arrow",
            options: {
              padding: 5,
            },
          },
        ],
      },
    }}
  >
    {content}
  </Tooltip>
);

export const renderActions = (params, handleShowInfo) => (
  <Box display="flex" justifyContent="space-between">
    {renderTooltip(
      TextConstants.VIEW_DETAILS,
      <IconButton
        color="primary"
        onClick={() => handleShowInfo(params.row)}
        size="small"
      >
        <OpenInNewIcon />
      </IconButton>
    )}
  </Box>
);
