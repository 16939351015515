import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Card,
  Typography,
  Grid,
  Alert,
  AlertTitle,
  Container,
  Chip,
  Stack,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { fetchTraveler } from "../../../../services/traveler";

const TravelVerification = () => {
  const { id } = useParams();
  const [travelerData, setTravelerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTravelerData = async () => {
      setLoading(true);
      const { data, error } = await fetchTraveler(id);
      if (error) {
        setError(error);
      } else {
        setTravelerData(data);
      }
      setLoading(false);
    };

    fetchTravelerData();
  }, [id]);

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!travelerData) return <Typography>No data found</Typography>;

  // Extract verification statuses from API response
  const verificationStatuses = {
    document: {
      success: travelerData.validationStatus.documentAuthenticated,
      message: travelerData.validationStatus.documentAuthenticated
        ? "Document successfully authenticated"
        : "Document authentication failed",
    },
    liveness: {
      success: travelerData.validationStatus.livenessCheckPassed,
      message: travelerData.validationStatus.livenessCheckPassed
        ? "Liveness check passed"
        : "Liveness check failed",
    },
    biometric: {
      success: travelerData.validationStatus.biometryMatched,
      message: travelerData.validationStatus.biometryMatched
        ? "Biometric data successfully matched"
        : "Biometric match failed",
    },
  };

  // Format date function
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // Helper function to format field names
  const formatFieldName = (fieldName) => {
    return fieldName
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Card sx={{ mb: 3, p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Travel Verification Status
        </Typography>
        <Alert
          severity={
            travelerData.dssValidationStatus.verified ? "success" : "error"
          }
          sx={{ mt: 2 }}
        >
          <AlertTitle>
            {travelerData.dssValidationStatus.verified ? "Pass" : "No Pass"}
          </AlertTitle>
          {travelerData.dssValidationStatus.verified
            ? "All checks passed successfully"
            : "One or more checks failed. Please see details below."}
        </Alert>
      </Card>

      <Card sx={{ mb: 3, p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Traveler Information
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ mb: 2 }}>
              <Typography color="text.secondary" variant="caption">
                Full Name
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {`${travelerData.document.givenName} ${travelerData.document.surname}`}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography color="text.secondary" variant="caption">
                Gender
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {travelerData.document.gender}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography color="text.secondary" variant="caption">
                Nationality
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {travelerData.document.nationality}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ mb: 2 }}>
              <Typography color="text.secondary" variant="caption">
                Date of Birth
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {formatDate(travelerData.document.dateOfBirth)}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography color="text.secondary" variant="caption">
                Document Type
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {travelerData.document.type}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography color="text.secondary" variant="caption">
                Document Number
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {travelerData.document.number}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography color="text.secondary" variant="caption">
                Document Expiration
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {formatDate(travelerData.document.expirationOn)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>

      <Card sx={{ mb: 3, p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Verification Status
        </Typography>
        {Object.entries(verificationStatuses).map(([key, status]) => (
          <Alert
            key={key}
            severity={status.success ? "success" : "error"}
            icon={status.success ? <CheckCircleIcon /> : <CancelIcon />}
            sx={{ mb: 2 }}
          >
            <AlertTitle>
              {key.charAt(0).toUpperCase() + key.slice(1)} Check
            </AlertTitle>
            {status.message}
          </Alert>
        ))}
      </Card>

      <Card sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Field Matches
        </Typography>
        <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
          {Object.entries(travelerData.dssValidationStatus.field_matches).map(
            ([field, matched]) => (
              <Chip
                key={field}
                label={`${formatFieldName(field)}: ${
                  matched ? "Matched" : "Not Matched"
                }`}
                color={matched ? "success" : "error"}
                variant="outlined"
                sx={{ m: 0.5 }}
              />
            )
          )}
        </Stack>
      </Card>
    </Container>
  );
};

export default TravelVerification;
