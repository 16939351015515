import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";

const DeleteVehicleDialog = ({ open, onClose, vehicleToDelete, onConfirm }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {"Delete Vehicle Confirmation"}
    </DialogTitle>
    <DialogContent>
      <Typography>
        Are you sure you want to delete the vehicle with license plate{" "}
        <strong>{vehicleToDelete?.licensePlate}</strong>? This action cannot be
        undone.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button onClick={onConfirm} sx={{ color: "red" }} autoFocus>
        DELETE
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteVehicleDialog;
