import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";

const StatisticCard = ({ title, value, icon: Icon, color = "primary" }) => (
  <Card
    sx={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
  >
    <CardContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" color="text.secondary">
          {title}
        </Typography>
        <Icon color={color} />
      </Box>
      <Typography variant="h4" sx={{ mt: 2, fontWeight: "bold" }}>
        {value}
      </Typography>
    </CardContent>
  </Card>
);

export default StatisticCard;
