import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import LoggerUtils from "../../utils/logger/LoggerUtils";
import TextConstants from "../../utils/constants/TextConstants";

export const getAllOnGoingTransactions = createAsyncThunk(
  "transactions/getAllOnGoingTransactions",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        TextConstants.ALL_TRANSACTIONS_ENDPOINT,
        {
          headers: {
            accept: "application/json",
            authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      LoggerUtils.logError("Error fetching ongoing transactions", error);
      return rejectWithValue(error.message);
    }
  }
);
