export const calculateTravelerStats = (travelers = []) => {
  const totalTravelers = travelers.length || 0;
  const documentsAuthenticated =
    travelers.filter((t) => t?.documentAuthenticated).length || 0;
  const livenessCheckPassed =
    travelers.filter((t) => t?.livenessCheckPassed).length || 0;
  const biometryMatched =
    travelers.filter((t) => t?.biometryMatched).length || 0;
  const dssVerified = travelers.filter((t) => t?.verified).length || 0;

  return {
    stats: {
      totalTravelers,
      documentsAuthenticated,
      livenessCheckPassed,
      biometryMatched,
      dssVerified,
    },
  };
};
