import { createSlice } from "@reduxjs/toolkit";
import {
  fetchVehiclesThunk,
  deleteVehicleThunk,
  verifyVehicleThunk,
} from "../thunks/vehicleThunks";

const vehicleSlice = createSlice({
  name: "vehicles",
  initialState: {
    vehicleData: [],
    selectedVehicle: null,
    status: "idle",
    error: null,
  },
  reducers: {
    addNewVehicle: (state, action) => {
      state.vehicleData.unshift(action.payload);
    },
    selectVehicle: (state, action) => {
      state.selectedVehicle = action.payload;
    },
    clearSelectedVehicle: (state) => {
      state.selectedVehicle = null;
    },
  },
  extraReducers: (builder) => {
    // fetchVehiclesThunk
    builder
      .addCase(fetchVehiclesThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVehiclesThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.vehicleData = action.payload;
      })
      .addCase(fetchVehiclesThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // deleteVehicleThunk
      .addCase(deleteVehicleThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteVehicleThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.vehicleData = state.vehicleData.filter(
          (vehicle) => vehicle.id !== action.payload
        );
      })
      .addCase(deleteVehicleThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // verifyVehicleThunk
      .addCase(verifyVehicleThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyVehicleThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        const verifiedVehicle = state.vehicleData.find(
          (vehicle) => vehicle.id === action.payload.id
        );
        if (verifiedVehicle) {
          verifiedVehicle.verified = true;
          verifiedVehicle.verificationMessage =
            action.payload.verificationMessage;
        }
      })
      .addCase(verifyVehicleThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { addNewVehicle, selectVehicle, clearSelectedVehicle } =
  vehicleSlice.actions;
export default vehicleSlice.reducer;
