import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ColorConstants from "../../utils/constants/ColorConstants";
import { NavItems } from "./components/NavItems";
import { DrawerContent } from "./components/DrawerContent";
import { TitleSection } from "./components/TitleSection";

export const NavBar = ({ onLogout }) => {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open);
  };

  return (
    <AppBar position="static" sx={{ bgcolor: ColorConstants.EU_BLUE_COLOR }}>
      <Toolbar>
        <TitleSection />

        {isMobile ? (
          <>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <DrawerContent
                toggleDrawer={toggleDrawer}
                onLogout={onLogout}
                navigate={navigate}
              />
            </Drawer>
          </>
        ) : (
          <NavItems onLogout={onLogout} navigate={navigate} />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
