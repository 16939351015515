import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#228B22",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

const VerificationDialog = ({
  open,
  onClose,
  formData,
  onChange,
  onSubmit,
  errors,
  isFormValid,
}) => (
  <ThemeProvider theme={theme}>
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="edit-dialog-title"
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 16,
          padding: "16px 8px",
        },
      }}
    >
      <DialogTitle id="edit-dialog-title" sx={{ m: 0, p: 2, pb: 0 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" component="div">
            Vehicle:{" "}
            <Typography component="span" sx={{ fontWeight: "bold" }}>
              {formData.licensePlate}
            </Typography>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <TextField
          margin="dense"
          label="Verification Message"
          name="verificationMessage"
          value={formData.verificationMessage}
          onChange={onChange}
          fullWidth
          error={!!errors.verificationMessage}
          helperText={errors.verificationMessage}
          multiline
          rows={4}
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: 2,
            },
          }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end", p: 2, pt: 0 }}>
        <Button
          onClick={onClose}
          color="inherit"
          variant="outlined"
          sx={{ borderRadius: 2 }}
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          disabled={!isFormValid}
          sx={{
            borderRadius: 2,
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "#006400",
            },
          }}
        >
          VERIFY
        </Button>
      </DialogActions>
    </Dialog>
  </ThemeProvider>
);

export default VerificationDialog;
