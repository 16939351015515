import React, { useState } from "react";
import {
  Container,
  Paper,
  TextField,
  Typography,
  Chip,
  Box,
  Grid,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const Profile = () => {
  const user = useSelector((state) => state.user.info);

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification((prev) => ({ ...prev, open: false }));
  };

  const renderTextField = (label, value, type = "text") => (
    <Grid item xs={12} sm={6}>
      <TextField fullWidth label={label} value={value} type={type} disabled />
    </Grid>
  );

  return (
    <Container maxWidth="md">
      <StyledPaper elevation={3}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            User Profile
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {renderTextField("Given Name", user?.given_name)}
          {renderTextField("Family Name", user?.family_name)}
          {renderTextField("Email", user?.email, "email")}
          {renderTextField("Preferred name", user?.preferred_username)}

          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Roles
            </Typography>
            <Box>
              {user?.roles.map((role, index) => (
                <StyledChip key={index} label={role} color="primary" />
              ))}
            </Box>
          </Grid>
        </Grid>
      </StyledPaper>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        message={notification.message}
      />
    </Container>
  );
};

export default Profile;
