class MessageUtils {
  // Vehicles
  static ERROR_VERIFYING_VEHICLE = (licensePlate) => (
    <span>
      Failed to pass vehicle <strong>{licensePlate}</strong>
    </span>
  );
  static ERROR_DELETING_VEHICLE = (licensePlate) => (
    <span>
      Failed to delete vehicle <strong>{licensePlate}</strong>
    </span>
  );

  static SUCCESS_VEHICLE_VERIFICATION = (licensePlate) => (
    <span>
      Vehicle <strong>{licensePlate}</strong> pass successfully
    </span>
  );
  static SUCCESS_VEHICLE_DELETED = (licensePlate) => (
    <span>
      Vehicle <strong>{licensePlate}</strong> deleted successfully
    </span>
  );

  // Travelers
  static ERROR_VERIFYING_TRAVELER = (traveler) => (
    <span>
      Failed to pass traveler <strong>{traveler}</strong>
    </span>
  );
  static ERROR_DELETING_TRAVELER = (traveler) => (
    <span>
      Failed to delete traveler <strong>{traveler}</strong>
    </span>
  );

  static SUCCESS_TRAVELER_VERIFICATION = (traveler) => (
    <span>
      Traveler <strong>{traveler}</strong> pass successfully
    </span>
  );
  static SUCCESS_TRAVELER_DELETED = (traveler) => (
    <span>
      Traveler <strong>{traveler}</strong> deleted successfully
    </span>
  );
}

export default MessageUtils;
