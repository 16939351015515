class LoggerUtils {
  static logError(action, error) {
    console.error(`Error during ${action}:`, error);
  }

  static logInfo(action, details = "") {
    console.log(`${action}${details ? `: ${details}` : ""}`);
  }
}

export default LoggerUtils;
