import axios from "axios";
import LoggerUtils from "../utils/logger/LoggerUtils";
import TextConstants from "../utils/constants/TextConstants";

export const getSpecificTransaction = async (
  token,
  transactionUuid,
  data = false
) => {
  try {
    const response = await axios.get(TextConstants.TRANSACTIONS_ENDPOINT, {
      params: {
        transaction_uuid: transactionUuid,
        data: data,
      },
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    LoggerUtils.logError("fetching specific transaction", error);
    throw new Error(error.message);
  }
};

export const setTransactionDecision = async (
  token,
  transactionUuid,
  decision
) => {
  try {
    const response = await axios.post(
      TextConstants.TRANSACTIONS_ENDPOINT,
      null,
      {
        params: {
          transaction_uuid: transactionUuid,
          decision: decision,
        },
        headers: {
          accept: "application/json",
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    LoggerUtils.logError("setting transaction decision", error);
    throw new Error(error.message);
  }
};
