import TextConstants from "../constants/TextConstants";
import { preprocessVehicleData, preprocessTravelerData } from "../helpers";

// Set up a WebSocket for real-time updates
export const setupVehicleWebSocket = (onNewVehicle) => {
  const socket = new WebSocket(TextConstants.VEHICLES_WEBSOCKET_URL);

  socket.onmessage = (event) => {
    const newVehicleData = preprocessVehicleData(JSON.parse(event.data));
    onNewVehicle(newVehicleData);
  };

  // Return a cleanup function to close the WebSocket when it's no longer needed
  return () => socket.close();
};

// Set up a WebSocket for real-time updates
export const setupTravelerWebSocket = (onNewVehicle) => {
  const socket = new WebSocket(TextConstants.TRAVELERS_WEBSOCKET_URL);

  socket.onmessage = (event) => {
    const newTravelerData = preprocessTravelerData(JSON.parse(event.data));
    onNewVehicle(newTravelerData);
  };

  // Return a cleanup function to close the WebSocket when it's no longer needed
  return () => socket.close();
};

export const setupAlertWebSocket = (onNewAlert) => {
  const alertSocket = new WebSocket(TextConstants.ALERTS_WEBSOCKET_URL);

  alertSocket.onmessage = (event) => {
    const newAlertData = JSON.parse(event.data);
    onNewAlert(newAlertData);
  };
  // Return a cleanup function to close the WebSocket when it's no longer needed
  return () => alertSocket.close();
};

export const setupCrowdCountingWebSocket = (onNewAlert) => {
  const alertSocket = new WebSocket(TextConstants.CROWD_COUNTING_WEBSOCKET_URL);

  alertSocket.onmessage = (event) => {
    const newAlertData = JSON.parse(event.data);
    onNewAlert(newAlertData);
  };
  // Return a cleanup function to close the WebSocket when it's no longer needed
  return () => alertSocket.close();
};
