import { createSlice } from "@reduxjs/toolkit";
import { getAllOnGoingTransactions } from "../thunks/transactionsThunks";

const transactionsSlice = createSlice({
  name: "transactions",
  initialState: {
    transactionsData: [],
    selectedTransaction: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetchTravelersThunk
    builder
      .addCase(getAllOnGoingTransactions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllOnGoingTransactions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.transactionsData = action.payload;
      })
      .addCase(getAllOnGoingTransactions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default transactionsSlice.reducer;
