import React from "react";
import { Chip } from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";

const StatusChip = ({
  verified,
  passLabel = "Pass",
  failLabel = "No Pass",
  onClick,
  className,
}) => (
  <Chip
    label={verified ? passLabel : failLabel}
    color={verified ? "success" : "error"}
    variant="outlined"
    size="small"
    icon={verified ? <CheckCircleIcon /> : <CancelIcon />}
    onClick={onClick}
    className={className}
    sx={{
      // ml: 1,
      fontWeight: 500,
      px: 1,
      "& .MuiChip-icon": {
        fontSize: 16,
        marginLeft: "4px",
      },
      borderRadius: 1,
      height: 24,
      boxShadow: 1,
      animation: "fadeIn 0.3s ease-in",
      "@keyframes fadeIn": {
        "0%": {
          opacity: 0,
          transform: "scale(0.9)",
        },
        "100%": {
          opacity: 1,
          transform: "scale(1)",
        },
      },
      "&:hover": {
        opacity: 0.9,
        cursor: onClick ? "pointer" : "default",
      },
    }}
  />
);

export default StatusChip;
